<template>
  <div>
    <b-table
      :items="claimList"
      responsive="sm"
      primary-key="idx"
      show-empty
      :fields="columnNames"
      empty-text="No matching records found"
      @row-clicked="goDetail"
    >
      <template #cell(applicant)="data">
        <div>
        {{ data.item.member_type }} / {{ data.item.member_idx }}
        </div>
      </template>
      <template #cell(target)="data">
        <div>
        {{ data.item.target_type }} / {{ data.item.target_idx }}
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            Showing {{ pageInfo.offset }} to {{ pageInfo.offset + pageInfo.limit }} of {{ pageInfo.total_count }} entries
          </span>
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="pageInfo.total_count"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @page-click="pageClick"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, onUnmounted, ref,
} from '@vue/composition-api'

import {
  BTable, BPagination, BCol, BRow,
} from 'bootstrap-vue'
import moment from 'moment'

import router from '@/router'
import store from '@/store'
import campaignStoreModule from './CampaignStoreModule'

export default defineComponent({
  props: {
    page: Number,
  },
  components: {
    BTable,
    BPagination,
    BCol,
    BRow,
  },
  setup(props, context) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const claimList = ref([])
    const pageInfo = ref({})
    const currentPage = ref(1)
    const perPage = 10
    const eventTypeMap = {
      CREATED: '시작 단계',
      CHECKING: '대상자 확인 중',
      CONFIRMING: '상대방의 액션 검토중',
      OBJECTING: '이의제기 단계',
      REQUESTING: '추가정보요청 단계',
      REPROCESSING: '재접수 단계',
      RESOLVING: '조정 단계'
    }
    const columnNames = [
      {
        key: 'idx',
        label: 'idx',
      },
      {
        key: 'campaign_title',
        label: '캠페인명',
      },
      {
        key: 'campaign_idx_id',
        label: '캠페인 idx',
      },
      {
        key: 'step',
        label: '상태',
        formatter: val => eventTypeMap[val]
      },
      {
        key: 'applicant',
        label: '접수자 (타입/idx)',
      },
      {
        key: 'target',
        label: '대상자 (타입/idx)',
      },
      {
        key: 'created_at',
        label: '시작일',
        formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}`
      },
    ]

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, campaignStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const fetchClaimList = () => {
      store.dispatch('app-user/fetchClaimList', { ...router.currentRoute.query })
        .then(response => {
          const { data } = response.data
          claimList.value = data.data
          pageInfo.value = data.page_info
          // FIXME
          currentPage.value = router.currentRoute.query.page
        })
    }

    const goDetail = item => {
      router.push({ name: 'claim-view', params: { idx: parseInt(item.idx, 10) } })
    }

    const pageClick = (button, page) => {
      currentPage.value = page
      router.push({
        name: 'claim-list', query: { page },
      })
        .then(() => fetchClaimList())
        .catch(() => {})
    }

    fetchClaimList()

    return {
      columnNames,
      claimList,
      pageInfo,
      currentPage,
      perPage,
      goDetail,
      pageClick,
    }
  },
})
</script>

<style>

</style>
