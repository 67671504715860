import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tagby/campaign/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchComptList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClaimList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/resolution/claim/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClaimDetail(ctx, idx) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/resolution/claim/${idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getComptDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/${idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getComptAppliers(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/${idx}/applier/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmPosting(ctx, form) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/${form.campaign_idx}/posting/`, {
            posting_idx: form.posting_idx,
            url: form.url,
            memo: form.memo,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    applyStateChange(ctx, form) {
      // let idxList = []
      // idxList.append(form.apply_idx)
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/${form.campaign_idx}/apply/`, {
            apply_idx_list: [form.apply_idx],
            state: form.state,
            memo: form.memo,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTimelines(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/timelines/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    applyAdd(ctx, form) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/${form.campaign_idx}/apply/add/`, {
            sns_idx: form.sns_idx,
            memo: form.memo,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    pending(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/pending/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    releasePending(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/release-pending/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchComptMissions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/missions/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMissionDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/missions/${params.mission_idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/update-mission/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEndlessMission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/update-endless-mission/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/cancel-invoice/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeInvoicePrice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/change-invoice-price/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeInvoiceExpectedPayDate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/change-invoice-expected-pay-date/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePointAdditionExpectedDate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/change-point-addition-expected-date/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchComptEtc(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/etc/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeManageStep(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/${params.idx}/change-manage-step/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    giveExtraPrePostingTime(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/${params.idx}/give-extra-days/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMissionHashTagList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/update-hashtag-list/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCampaign(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/update/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchComptDeliveries(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/${idx}/delivery/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchShipmentCompanyList() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/delivery/shipment-company/list/`,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTrackingNo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/update-delivery/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCommentToPrePosting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/pre-posting/${params.idx}/add-comment/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmPrePosting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/pre-posting/${params.idx}/confirm/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postNotification(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/post-notification/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadSelectedApplier(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/campaign/compt/download-selected-applier/`, params, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCanBeProgressed(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/can-be-progressed/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeProgressed(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/change-progressed/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
